<template>
    <div>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on }">
                <v-text-field
                    :value="computedDateFormattedMomentjs"
                    :placeholder="placeholder"
                    v-on="on"
                    outlined

                    :disabled="readOnly"
                    :class="classes"
                    :error="error"
                    onkeydown="return false;"
                ></v-text-field>
            </template>
            <v-date-picker
                class="border"
                v-model="date"
                @input="dataEscolhida"
                ref="form_base_date_picker"
                no-title
                header-color="#453630"
                color="#453630"
                locale="en"
                :min="min"
                :max="max"
                :show-current="false"
                :active-picker.sync="activePicker"
            ></v-date-picker>
        </v-menu>
        <span class="message-error" v-if="error && errorMessage">{{ errorMessage }}</span>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: [
        'placeholder',
        'value',
        'error',
        'errorMessage',
        'readOnly',
        'min',
        'max',
        'required',
        'formDirty',
        'disableDelete',
        'name',
    ],
    data() {
        return {
            activePicker: null,
            menu: false,
            date: null,
        };
    },
    mounted() {
        if (this.value) {
            this.date = moment(this.value).format('YYYY-MM-DD');
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'));
        },
    },
    computed: {
        computedDateFormattedMomentjs() {
            return this.value ? moment(this.value).format('DD/MM/YYYY') : '';
        },
        classes() {
            return {
                'input-group': true,
                'input-group-error': this.error,
            };
        },
    },
    methods: {
        formatDate(date) {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const day = date.substring(8, 11);
            const hour = '00';
            const minute = '00';
            const second = '00';

            return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.000' + 'Z';
        },
        dataEscolhida() {
            this.$emit('input', this.formatDate(this.date));
            this.menu = false;
        },
        handleInput(e) {
            if (this.disableDelete && (e.key === 'Backspace' || e.key === 'Delete')) {
                return e.preventDefault(); // Don't do anything to the input
            }
        },
    },
};
</script>

<style scoped>
.message-error {
    color: #f72b2f;
    font-family: SF Thonburi Regular;
    font-weight: 400;
    font-size: 16px !important;
    display: block;
    margin-top: 4px;
}
.v-text-field__slot input {
    padding-left: 8px !important;
}
</style>
