<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            display: false,
            loading: true,
        };
    },
    async mounted() {
        let path = $cookies.get('LINE_LIFF_ORIGIN_PATH_NT');
        this.loading = false;
        if (path !== undefined && path !== null && path !== 'undefined' && path !== 'null') {
            this.$router.push(path);
            $cookies.remove('LINE_LIFF_ORIGIN_PATH_NT');
        }
    },
};
</script>
