<template>
    <div class="loading" data-cy="loading-wrap">
        <div class="lds-ring" data-cy="loading-lds-ring">
            <div :style="`border: 6px solid ${color || '#fff'}; border-color: ${color || '#fff'} transparent transparent transparent;`"></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['opacity', 'color', 'bgColor']
}
</script>

<style scoped>
.loading {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.1); */
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    margin: 8px;
    /* border: 8px solid #fff; */
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    /* border-color: #fff transparent transparent transparent; */
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>